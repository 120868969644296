import request from '@/utils/request'

export default {
	// 分类区域数据
    getShop(){
        return request({
            url:"/api/page/home",
            method:"post"
        })
    },
	// 公告列表
	getNoticeList(query){
		return request({
			url:'/api/page/notice',
			data:{...query},
			method:"post"
		})
	},
	// 获取公告详情
	getNoticeDetail(query){
		return request({
			url:"/api/page/noticeDetail",
			data:{...query},
			method:"post"
		})
	},
	// 图片广告
	getPicAdd(query){
		return request({
			url:"/api/page/picAd",
			data:{...query},
			method:"post"
		})
	},
	// 轮播图
	getBannerList(query){
		return request({
			url:"/api/page/banner",
			data:{...query},
			method:"post"
		})
	},
	// 获取订单数量
	getOrderNum(){
		return request({
			url:"/api/user.order/counts",
			method:"post"
		})
	},
	
}