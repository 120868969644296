import request from '@/utils/request'

export default {
	getDetail(query){
		return request({
			url:"/api/page/artDetail",
			data:{...query},
			method:"post"
		})
	},
	//获取富文本列表
	getArticles(){
		return request({
			url:"/api/page/articles",
			method:"post"
		})
	},
	// 投诉
	setFeedback(query){
		return request({
			url:"/api/page/feedback",
			data:{...query},
			method:"post"
		})
	},
	// 获取系统设置信息
	getSites(){
		return request({
			url:"/api/page/sites",
			method:"post"
		})
	},
	addPurchase(query){
		return request({
			url:"/api/user.index/addPurchase",
			method:"post",
			data:{...query}
		})
	}
	
}