import Vue from 'vue'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
// 工具方法
import * as util from "./utils/index.js"
// 挂载到Vue原型上
Vue.prototype.$util=util
// 挂载到vue实例上
Vue.prototype.$store=store
// 引入公共样式
import "./css/comm.css"
import "./css/transition.css"
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
		Vue.prototype.$bus = this //定义全局事件总线
	},
}).$mount('#app')
