import Vue from "vue"

import Vuex from "vuex"
import api from "@/api/user.js"
import homeApi from "@/api/home"
import home from "@/api/home"
Vue.use(Vuex) //vue的插件机制

// Vuex.Store的构造器选项
const store = new Vuex.Store({
	// 存放状态
	state: {
		userInfo: {}, //用户信息
		accessToken: "", //访问令牌
		orderCount: {},
		siteObj:{
			contact_phone:"",
			coupon_ad_img:"",
			copy:"",
			down_qrcode:[],
			icp:'',
			logo:"",
			notes:"",
			points_ad_img:"",
			support:"",
			work_time:"",
			grey_bg:0
		},
		picList:[]
	},
	// store的计算属性
	getters: {
		// 是否登录
		hasLogin(state) {
			return !!state.accessToken //取反两次把原数据转换成布尔类型，有值为true

		}
	},
	actions: {
		//获取用户信息
		getUserInfo({
			commit
		}) {
			return new Promise((resolve, reject) => {
				api.getMyUser()
					.then(response => {
						let vuexData = {
							userInfo: response.data.userInfo
						}
						commit('setToken', vuexData)
					})
					.catch(reject)
			})
		},
		//获取用户订单信息
		getOrderCount({
			commit
		}) {
			return new Promise((resolve, reject) => {
				homeApi.getOrderNum().then(res => {
					commit('order_count', res.data)
				}).catch(reject)
			})
		},

	},
	// 更新store的状态
	mutations: {
		// 更新数据
		setState(state, obj) {
			for (let key in obj) {
				// 每个对象的的key作为状态名，value作为状态值
				state[key] = obj[key]
			}
		},
		order_count(state, data) {
			state.orderCount = data
			localStorage.setItem("orderCount", JSON.stringify(data))
		},
		set_pic_listr(state, data) {
			state.picList = data
		},
		// 更新用户登录状态
		setToken(state, data) {
			// 解构属性

			const {
				userInfo,
				access_token
			} = data
			// 状态赋值保存
			if (userInfo) {
				state.userInfo = userInfo
				localStorage.setItem("userInfo", JSON.stringify(userInfo))

			}
			if (access_token) {
				state.accessToken = access_token
				localStorage.setItem("accessToken", access_token)
			}
		},
		site_obj_data(state, data){
			state.siteObj = data
		},
		// 退出登录
		loginOut(state) {
			state.userInfo = {}
			state.accessToken = ""
			state.orderCount = {}
			localStorage.removeItem('userInfo')
			localStorage.removeItem('accessToken')
			localStorage.removeItem('orderCount')
		}
	}
})
// 导出store实例
export default store