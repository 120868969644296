import request from '@/utils/request'
export default {
	// 获取用户信息
	getMyUser(){
		return request({
			url:"/api/user/detail",
			method:"post"
		})
	},
	// 更新用户信息
	eidtUser(query){
		return request({
			url:"/api/user/edit",
			data:{...query},
			method:"post"
		})
	},
	// 绑定新手机号码
	setBindPhone(query){
		return request({
			url:"/api/user/bindPhone",
			data:{...query},
			method:"post"
		})
	},
	// 获取充值记录
	getRechargeList(query){
		return request({
			url:"/api/recharge.order/lists",
			data:{...query},
			method:"post"
		})
	},
	// 获取充值套餐和设置信息
	getRechargeIndex(){
		return request({
			url:"/api/recharge/index",
			method:"post"
		})
	},
	// 创建充值订单
	createRechartge(query){
		return request({
			url:'/api/recharge/submit',
			data:{...query},
			method:"post"
		})
	},
	// 余额明细
	getLogList(query){
		return request({
			url:"/api/balance.log/lists",
			data:{...query},
			method:"post"
		})
	},
	// 我的收藏
	getMyCollist(query){
		return request({
			url:"/api/goods/colist",
			data:{...query},
			method:"post"
		})
	},
	// 查看物流
	getLogistics(query){
		return request({
			url:"/api/user.order/express",
			data:{...query},
			method:"post"
		})
	}
}