import axios from 'axios'
import { Message, Loading } from 'element-ui'
import store from '@/store'
const virtualPath = "/";
// 创建一个axios实例
const service = axios.create({
  // 由于这里时我自己做了跨域代理，所以去掉了
  baseURL: virtualPath,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    channel: "pc"
  },
  timeout: 150000
})
service.interceptors.request.use(
  config => {
    const accessTokenKey = store.state.accessToken
    // const userType = store.state.userDetail.user_type
    if (accessTokenKey) {
      config.headers.token = accessTokenKey
      // config.headers['user-type']=userType
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {

    const res = response.data
    // 如果自定义代码不是200，则判断为错误
    if (res.code == 1) {
      return res
    } else {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
      if (res.code == 401 || res.code == 403 || res.code ==-1) {
        // 直接重置状态
        store.commit('loginOut')
      }

      return Promise.reject(res)
    }
  },
  error => {
    console.log(error)
    // 非401未认证，直接放行
    // if (error.response && error.response.status !== 401) {
    //   // Message({ message: error.msg, type: 'error', duration: 5 * 1000 })

    // }
    // 直接重置状态
    // store.dispatch('increment')
    return Promise.reject(error)
  }
)

export default service
