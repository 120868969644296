<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	
	import api from "@/api/tool"
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			if(this.isMobile()){
				window.location.href='https://yungojc.zyrkeji.cn/h5/#/'
			}
			this.initLogin()
			this.getSites()
		},
		methods:{
			isMobile() {
				return window.navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
			},
			getSites(){
				api.getSites().then(res=>{
					localStorage.setItem("siteObj", JSON.stringify(res.data))
					this.$store.commit('site_obj_data',res.data)
				})
			},
			// 初始化登录状态
			initLogin() {
				const userInfo = JSON.parse(localStorage.getItem('userInfo'))
				const accessToken = localStorage.getItem('accessToken')
				const orderCount = JSON.parse(localStorage.getItem('orderCount'))
				if (userInfo && accessToken) {
					this.$store.commit('setState', {
						userInfo,
						accessToken
					})
					this.$store.commit('order_count',orderCount)
				}
			},
		}
	}
</script>
<style lang="less">
	#app {
		min-width: 1200px;
		overflow-x: hidden;
		background-color: #F8F8F8;
	}

	
</style>