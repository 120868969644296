import Vue from 'vue'
import VueRouter from 'vue-router'
import {
	Message
} from 'element-ui'
Vue.use(VueRouter)
//核心：解决代码冗余
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: "/",
		component: () => import('@/views/layout/index.vue'),
		children: [{
				path: "/",
				name: "Home",
				component: () => import('@/views/layout/Home/index'),
				meta: {
					title: "首页"
				},
			},
			{
				path: "/goods/detail",
				name: "goodsDetail",
				component: () => import('@/views/layout/goods/detail'),
				meta: {
					title: "商品详情"
				},
			},
			{
				path: "/points/index",
				name: "pointsMall",
				component: () => import('@/views/layout/points/index'),
				meta: {
					title: "积分商城",
					requiresAuth: true
				},
			},
			{
				path: "/points/detail",
				name: "pointsDetail",
				component: () => import('@/views/layout/points/detail'),
				meta: {
					title: "积分明细",
					requiresAuth: true
				},
			},
			{
				path: "/goods/all",
				name: "allShop",
				component: () => import('@/views/layout/goods/all'),
				meta: {
					title: "全部商品"
				},
			},
			{
				path: "/goods/piceList",
				name: "piceShop",
				component: () => import('@/views/layout/goods/piceList'),
				meta: {
					title: "识别商品"
				},
			},
			{
				path: "/goods/verify",
				name: "goodsVerify",
				component: () => import('@/views/layout/goods/verify'),
				meta: {
					title: "确认订单",
					requiresAuth: true
				},
			},
			{
				path: "/coupon/index",
				name: "drawCoupon",
				component: () => import('@/views/layout/coupon/index'),
				meta: {
					title: "领券中心"
				},
			},
			{
				path: "/investment/index",
				name: "investment",
				component: () => import('@/views/layout/investment/index'),
				meta: {
					title: "招商加盟"
				},
			},
			{
				path: "/announcement/index",
				name: "announcement",
				component: () => import('@/views/layout/announcement/index'),
				meta: {
					title: "公告详情"
				},
			},
			{
				path: "/purchasing/index",
				name: "purchasing",
				component: () => import('@/views/layout/purchasing/index'),
				meta: {
					title: "一键式采购",
					requiresAuth: true
				},
			},
			{
				path: "/order/detail/index",
				name: "orderDetail",
				component: () => import('@/views/layout/orderDetail/index'),
				meta: {
					title: "订单详情",
					requiresAuth: true
				},
			},
			{
				path: "/order/comment/index",
				name: "orderComment",
				component: () => import('@/views/layout/orderDetail/comment'),
				meta: {
					title: "订单评价",
					requiresAuth: true
				},
			},
			{
				path: "/userInfo/collect/index",
				name: "collect",
				component: () => import('@/views/layout/userInfo/collect/index'),
				meta: {
					title: "我的收藏",
					requiresAuth: true
				},
			},
			{
				path: "/userInfo/card/index",
				name: "card",
				component: () => import('@/views/layout/userInfo/card/index'),
				meta: {
					title: "我的购物车",
					requiresAuth: true
				},
			},
			{
				path: "/userInfo/card/verify",
				name: "verify",
				component: () => import('@/views/layout/userInfo/card/verify'),
				meta: {
					title: "确认订单",
					requiresAuth: true
				},
			},
			{
				path: "/userInfo/index",
				component: () => import('@/views/layout/userInfo/index'),
				children: [{
						path: "/userInfo/index",
						name: "basic",
						component: () => import('@/views/layout/userInfo/basic/index'),
						meta: {
							title: "基本信息",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/editPhone",
						name: "editPhone",
						component: () => import('@/views/layout/userInfo/editPhone/index'),
						meta: {
							title: "修改手机号",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/address",
						name: "myAddress",
						component: () => import('@/views/layout/userInfo/address/index'),
						meta: {
							title: "收货地址",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/balance",
						name: "myBalance",
						component: () => import('@/views/layout/userInfo/balance/index'),
						meta: {
							title: "我的余额",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/topUp",
						name: "topUp",
						component: () => import('@/views/layout/userInfo/topUp/index'),
						meta: {
							title: "充值",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/coupon",
						name: "myCoupon",
						component: () => import('@/views/layout/userInfo/coupon/index'),
						meta: {
							title: "我的优惠卷",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/order",
						name: "myOrder",
						component: () => import('@/views/layout/userInfo/order/index'),
						meta: {
							title: "我的订单",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/refund/add",
						name: "refundAdd",
						component: () => import('@/views/layout/userInfo/refund/add'),
						meta: {
							title: "申请售后",
							requiresAuth: true
						},
					},
					{
						path: "/userInfo/refund/index",
						name: "myRefund",
						component: () => import('@/views/layout/userInfo/refund/index'),
						meta: {
							title: "我的售后",
							requiresAuth: true
						},
					},
				]
			},
			{
				path: "/parse/index",
				component: () => import('@/views/layout/parse/index'),
				meta: {
					title: "服务",
				},
			}
		]
	},
	{
		path: '/404',
		component: () => import('@/views/404'),
		hidden: true
	},
	// 404 page must be placed at the end !!!
	{
		path: '*',
		redirect: '/404',
		hidden: true
	}
]

const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	let accessToken = localStorage.getItem('accessToken')
	if (!accessToken && to.meta.requiresAuth) {
		Message({
			message: '请先登录',
			type: 'error',
			duration: 3 * 1000
		})
		next({
			path: '/',
		})
	} else {
		next()
	}
})
export default router